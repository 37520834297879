/* eslint-disable jsx-a11y/control-has-associated-label */
import { filter, find, get, isEmpty, isEqual, map, omit, some } from 'lodash';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button } from '../../lib/react-components';

import { colors } from '../../constants/colors';
import { regionsByMarket } from '../../constants/states';
import { Toggle } from '../toggle';
import { useUpdateRestaurants } from '../../hooks';

const fieldsThatDontTriggerSave = ['updatedAt', 'lastTouchedUserId'];

const Table = ({ queryData }) => {
  const [modifiedData, setModifiedData] = useState(queryData);
  const [stateFilter, setStateFilter] = useState('all');
  const [touchedData, setTouchedData] = useState([]);
  const [confirmationText, setConfirmationText] = useState(null);

  const updateRestaurants = useUpdateRestaurants();

  useEffect(() => {
    setModifiedData(queryData);
  }, [queryData]);

  useEffect(() => {
    setTouchedData(
      filter(
        modifiedData,
        (restaurant, i) =>
          !isEqual(omit(restaurant, fieldsThatDontTriggerSave), omit(queryData[i], fieldsThatDontTriggerSave))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedData]);

  useEffect(() => {
    const preventLeaveWithoutSaving = event => {
      if (some(touchedData)) {
        event.preventDefault();
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    };

    const eventName = 'beforeunload';
    window.addEventListener(eventName, preventLeaveWithoutSaving);
    return () => window.removeEventListener(eventName, preventLeaveWithoutSaving);
  }, [touchedData]);

  const resetData = () => {
    setConfirmationText(null);
    setModifiedData(queryData);
  };

  const saveData = async () => {
    // map the restaurants to the format the endpoint expects
    const valuesToSend = touchedData.map(restaurant => ({
      restaurantId: restaurant.id,
      orderingEnabled: restaurant.orderingEnabled,
      tableOrderingEnabled: restaurant.tableOrderingEnabled,
      deliveryEnabled: restaurant.deliveryEnabled,
      pickUpEnabled: restaurant.pickUpEnabled,
      dineInGuestCheckoutEnabled: restaurant.dineInGuestCheckoutEnabled,
      deliveryGuestCheckoutEnabled: restaurant.deliveryGuestCheckoutEnabled,
      pickUpGuestCheckoutEnabled: restaurant.pickUpGuestCheckoutEnabled,
    }));

    if (!confirmationText) {
      setConfirmationText(
        `Are you sure you want to make updates to the following restaurants?: ${valuesToSend
          .map(restaurant => restaurant.restaurantId)
          .join(', ')}`
      );
    } else {
      setConfirmationText(null);
      updateRestaurants.mutate(valuesToSend);
    }
  };

  const updateData = (id, columnId, value) => {
    setModifiedData(old =>
      old.map(row => {
        if (row.id === id) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
    setConfirmationText(null);
  };

  const saveCancelButtons = (
    <>
      {confirmationText && <Box mb={2}>{confirmationText}</Box>}
      <Button isDisabled={isEmpty(touchedData)} onClick={saveData} mr={3}>
        Save
      </Button>
      <Button variant="secondary" onClick={resetData}>
        Cancel
      </Button>
    </>
  );

  const isNewZealand = process.env.market === 'nz';
  const states = regionsByMarket[process.env.market];

  const isOrderTypeReadonly = process.env.NEXT_PUBLIC_IS_ORDER_TYPE_READONLY === 'true' || false;

  return (
    <Box px={3}>
      <Box>{saveCancelButtons}</Box>

      <Box py={4}>
        <select onChange={event => setStateFilter(event.target.value)} value={stateFilter}>
          <option value="all">All {isNewZealand ? 'Regions' : 'States'}</option>
          {map(states, state => (
            <option value={state.value} key={state.value}>
              {state.longName}
            </option>
          ))}
        </select>
      </Box>

      <StyledTable>
        <thead>
          <tr>
            <th>Id</th>
            <th>Restaurant Name</th>
            <th>State</th>
            <th>Ordering Enabled?</th>
            <th>Table Ordering Enabled?{isOrderTypeReadonly ? ' (read only)' : ''}</th>
            <th>Delivery Enabled?{isOrderTypeReadonly ? ' (read only)' : ''}</th>
            <th>Pick Up Enabled?{isOrderTypeReadonly ? ' (read only)' : ''}</th>
            <th>Dine In Guest Checkout Enabled?</th>
            <th>Delivery Guest Checkout Enabled?</th>
            <th>Pick Up Guest Checkout Enabled?</th>
          </tr>
        </thead>
        <tbody>
          {modifiedData
            .filter(restaurant => stateFilter === 'all' || get(restaurant, 'address.state') === stateFilter)
            .filter(restaurant => !!restaurant.name) // filter out all names that are undefined+null+empty
            .map(restaurant => (
              <tr key={restaurant.id}>
                <td>{restaurant.id}</td>
                <td>
                  <Link href={`restaurants/${restaurant.id}`}>{restaurant.name}</Link>
                </td>
                <td>
                  {(find(states, { value: get(restaurant, 'address.state') }) || {}).longName ||
                    get(restaurant, 'address.state')}
                </td>
                <td>
                  <Toggle
                    value={restaurant.orderingEnabled}
                    onClick={() => updateData(restaurant.id, 'orderingEnabled', !restaurant.orderingEnabled)}
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.tableOrderingEnabled}
                    onClick={() => updateData(restaurant.id, 'tableOrderingEnabled', !restaurant.tableOrderingEnabled)}
                    disabled={isOrderTypeReadonly}
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.deliveryEnabled}
                    onClick={() => updateData(restaurant.id, 'deliveryEnabled', !restaurant.deliveryEnabled)}
                    disabled={isOrderTypeReadonly}
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.pickUpEnabled}
                    onClick={() => updateData(restaurant.id, 'pickUpEnabled', !restaurant.pickUpEnabled)}
                    disabled={isOrderTypeReadonly}
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.dineInGuestCheckoutEnabled}
                    onClick={() =>
                      updateData(restaurant.id, 'dineInGuestCheckoutEnabled', !restaurant.dineInGuestCheckoutEnabled)
                    }
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.deliveryGuestCheckoutEnabled}
                    onClick={() =>
                      updateData(
                        restaurant.id,
                        'deliveryGuestCheckoutEnabled',
                        !restaurant.deliveryGuestCheckoutEnabled
                      )
                    }
                  />
                </td>
                <td>
                  <Toggle
                    value={restaurant.pickUpGuestCheckoutEnabled}
                    onClick={() =>
                      updateData(restaurant.id, 'pickUpGuestCheckoutEnabled', !restaurant.pickUpGuestCheckoutEnabled)
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </StyledTable>

      {some(touchedData) && (
        <Box position="fixed" py="2" bottom={0} width="100%" backgroundColor="white">
          {saveCancelButtons}
        </Box>
      )}
    </Box>
  );
};

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 4rem;
  border-spacing: 0;
  border: 2px solid ${colors.grey300};

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    background-color: ${colors.grey100};
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 2px solid ${colors.grey300};
    border-right: 2px solid ${colors.grey300};

    :last-child {
      border-right: 0;
    }
  }
`;

Table.propTypes = {
  queryData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export { Table };
