import { Heading, Text } from '../../lib/react-components';

const NotSignedIn = () => (
  <>
    <Heading>Please sign in</Heading>
    <Text>You must be signed in to view the protected content on this page.</Text>
  </>
);

export { NotSignedIn };
